import {
  TaxServiceDepartment,
  TaxServiceName,
  TaxServiceStatus,
} from "../../../api/graphql/schema.generated";

export const TAX_SERVICE_NAMES_BY_DEPARTMENT = {
  [TaxServiceDepartment.TAX_DECLARATION]: [
    TaxServiceName.EUER,
    TaxServiceName.VAT_ANNUAL_DECLARATIONS,
    TaxServiceName.TRADE_TAX_DECLARATIONS,
    TaxServiceName.PRIVATE_ANGABEN,
    TaxServiceName.ATTACHMENT_G,
    TaxServiceName.ATTACHMENT_S,
    TaxServiceName.ATTACHMENT_PENSION_EXPENSE,
    TaxServiceName.ATTACHMENT_SPECIAL_EXPENSE,
    TaxServiceName.ATTACHMENT_EXTRAORDINARY_EXPENSES,
    TaxServiceName.ATTACHMENT_HOUSEHOLD_RELATED_EXPENSES,
    TaxServiceName.ATTACHMENT_K,
    TaxServiceName.ATTACHMENT_AV,
    TaxServiceName.ATTACHMENT_JOINT_ASSESSMENT,
    TaxServiceName.FACILITY_FOR_CHILDREN_OVER_18_YEARS,
    TaxServiceName.ATTACHMENT,
    TaxServiceName.ATTACHMENT_N,
    TaxServiceName.ATTACHMENT_KAP_CAPITAL_GAINS,
    TaxServiceName.ATTACHMENT_SO,
    TaxServiceName.ATTACHMENT_AUS,
    TaxServiceName.ATTACHMENT_R,
    TaxServiceName.SEPARATE_AND_UNIFORM_ASSESSMENT,
    TaxServiceName.TAX_BILL_CHECK,
    TaxServiceName.ATTACHMENT_KAP_TRADING_OR_OTHERS,
    TaxServiceName.ATTACHMENT_V_PLUS_V,
    TaxServiceName.OBJECTIONS,
    TaxServiceName.OTHER_SERVICE_FLAT_RATE,
  ],
  [TaxServiceDepartment.CONSULTING]: [
    TaxServiceName.TRANSACTIONAL_CONSULTING,
    TaxServiceName.INVOICING_ISSUES_INCLUDING_CORRECTION_OF_INVOICES,
    TaxServiceName.CAR_TAXATION_1_PERCENT_METHOD_LOGBOOK,
    TaxServiceName.TRAVEL_COST,
    TaxServiceName.HOME_OFFICE,
    TaxServiceName.BUSINESS_ASSETS,
    TaxServiceName.QUESTIONNAIRE_FOR_TAX_REGISTRATION_SECOND_BUSINESS,
    TaxServiceName.ADJUSTMENTS_INCOME_TAX_PREPAYMENTS,
    TaxServiceName.ADJUSTMENTS_TRADE_TAX_PREPAYMENTS,
    TaxServiceName.CONSULTATION_ON_THE_PURCHASE_OF_A_CAR,
    TaxServiceName.CHECK_SMALL_BUSINESS_VS_NORMAL_TAX,
    TaxServiceName.END_OF_YEAR_MEETING,
    TaxServiceName.INVESTMENT_CONSULTING,
    TaxServiceName.ALIMONY_PAYMENTS,
    TaxServiceName.PROFESSIONAL_TRAINING,
    TaxServiceName.ILLNESS_AND_DISABILITY,
    TaxServiceName.DIVORCE_COSTS,
    TaxServiceName.NATURAL_HAZARD_DAMAGE,
    TaxServiceName.HOME_OWNERSHIP,
    TaxServiceName.OPERATIONAL_AUDITS,
    TaxServiceName.REVIEW_OF_CONTRACTS,
    TaxServiceName.DEFERRAL_REQUESTS,
    TaxServiceName.ESOP_VSOP_CONSULTING,
    TaxServiceName.ADVICE_ON_MOVING_OUT_OF_GERMANY,
    TaxServiceName.ADVICE_IN_CONNECTION_WITH_SEVERANCE_PAYMENTS,
    TaxServiceName.ASSESSMENT_OF_INVESTMENTS_IN_OTHER_COMPANIES,
    TaxServiceName.ADVICE_ON_OTHER_TAX_ISSUES,
    TaxServiceName.OTHER_SERVICE_FLAT_RATE,
  ],
  [TaxServiceDepartment.OTHER_SERVICES]: [
    TaxServiceName.REPORT_MOVE_TO_THE_TAX_OFFICE,
    TaxServiceName.APPLY_FOR_VAT_ID,
    TaxServiceName.APPLY_FOR_PERMANENT_EXTENSION,
    TaxServiceName.FEES_FOR_LATE_SUBMISSION_OF_DOCUMENTS,
    TaxServiceName.EXPRESS_FEE_FOR_TAX_DECLARATIONS,
    TaxServiceName.FEE_FOR_PHYSICAL_DOCUMENT_RETRIEVAL,
    TaxServiceName.REACTIVATION_FEE,
    TaxServiceName.NON_COOPERATION_FEE,
    TaxServiceName.INITIAL_CONSULTATION,
    TaxServiceName.MANUAL_BOOKKEEPING,
    TaxServiceName.CONFIRMATION_SELF_EMPLOYMENT,
    TaxServiceName.SIGNED_BWA,
    TaxServiceName.LIST_OF_TOTALS_AND_BALANCES,
    TaxServiceName.OTHER_SERVICES,
    TaxServiceName.OTHER_SERVICE_FLAT_RATE,
  ],
};

export const TAX_SERVICE_TAX_YEARS = [2021, 2022, 2023, 2024, 2025];
export const TAX_SERVICE_TAX_YEAR_OPTIONS = TAX_SERVICE_TAX_YEARS.map(
  (year) => ({ value: year, label: year })
);

export const TIME_TRACKING_REQUIRED_TAX_SERVICES = [
  TaxServiceName.ATTACHMENT_KAP_TRADING_OR_OTHERS,
  TaxServiceName.ATTACHMENT_V_PLUS_V,
  TaxServiceName.OBJECTIONS,
  TaxServiceName.CHECK_SMALL_BUSINESS_VS_NORMAL_TAX,
  TaxServiceName.INVESTMENT_CONSULTING,
  TaxServiceName.ALIMONY_PAYMENTS,
  TaxServiceName.PROFESSIONAL_TRAINING,
  TaxServiceName.ILLNESS_AND_DISABILITY,
  TaxServiceName.DIVORCE_COSTS,
  TaxServiceName.NATURAL_HAZARD_DAMAGE,
  TaxServiceName.HOME_OWNERSHIP,
  TaxServiceName.OPERATIONAL_AUDITS,
  TaxServiceName.REVIEW_OF_CONTRACTS,
  TaxServiceName.DEFERRAL_REQUESTS,
  TaxServiceName.ESOP_VSOP_CONSULTING,
  TaxServiceName.ADVICE_ON_MOVING_OUT_OF_GERMANY,
  TaxServiceName.ADVICE_IN_CONNECTION_WITH_SEVERANCE_PAYMENTS,
  TaxServiceName.ASSESSMENT_OF_INVESTMENTS_IN_OTHER_COMPANIES,
  TaxServiceName.ADVICE_ON_OTHER_TAX_ISSUES,
  TaxServiceName.MANUAL_BOOKKEEPING,
  TaxServiceName.OTHER_SERVICES,
];

export const TAX_YEAR_REQUIRED_TAX_SERVICES = [
  TaxServiceName.EUER,
  TaxServiceName.VAT_ANNUAL_DECLARATIONS,
  TaxServiceName.TRADE_TAX_DECLARATIONS,
  TaxServiceName.PRIVATE_ANGABEN,
  TaxServiceName.ATTACHMENT_G,
  TaxServiceName.ATTACHMENT_S,
  TaxServiceName.ATTACHMENT_PENSION_EXPENSE,
  TaxServiceName.ATTACHMENT_SPECIAL_EXPENSE,
  TaxServiceName.ATTACHMENT_EXTRAORDINARY_EXPENSES,
  TaxServiceName.ATTACHMENT_HOUSEHOLD_RELATED_EXPENSES,
  TaxServiceName.ATTACHMENT_K,
  TaxServiceName.ATTACHMENT_AV,
  TaxServiceName.ATTACHMENT_JOINT_ASSESSMENT,
  TaxServiceName.FACILITY_FOR_CHILDREN_OVER_18_YEARS,
  TaxServiceName.ATTACHMENT,
  TaxServiceName.ATTACHMENT_N,
  TaxServiceName.ATTACHMENT_KAP_CAPITAL_GAINS,
  TaxServiceName.ATTACHMENT_SO,
  TaxServiceName.ATTACHMENT_AUS,
  TaxServiceName.ATTACHMENT_R,
  TaxServiceName.SEPARATE_AND_UNIFORM_ASSESSMENT,
  TaxServiceName.TAX_BILL_CHECK,
  TaxServiceName.ATTACHMENT_KAP_TRADING_OR_OTHERS,
  TaxServiceName.ATTACHMENT_V_PLUS_V,
  TaxServiceName.OBJECTIONS,
  TaxServiceName.END_OF_YEAR_MEETING,
  TaxServiceName.MANUAL_BOOKKEEPING,
  TaxServiceName.LIST_OF_TOTALS_AND_BALANCES,
  TaxServiceName.OTHER_SERVICES,
  TaxServiceName.OTHER_SERVICE_FLAT_RATE,
];

export const INTERNAL_NOTE_REQUIRED_SERVICES = [
  TaxServiceName.ADVICE_ON_OTHER_TAX_ISSUES,
  TaxServiceName.OTHER_SERVICES,
];

export const TAX_SERVICE_STATUS_LABELS = {
  [TaxServiceStatus.BLOCKED]: "Blocked",
  [TaxServiceStatus.WILL_BE_BILLED]: "Abrechnung Erfolg",
  [TaxServiceStatus.NOT_PAYED_YET]: "Nicht bezahlt",
  [TaxServiceStatus.PAYED]: "Bezahlt",
};

export const FREE_TAX_SERVICES = [
  TaxServiceName.EUER,
  TaxServiceName.VAT_ANNUAL_DECLARATIONS,
  TaxServiceName.TRADE_TAX_DECLARATIONS,
  TaxServiceName.PRIVATE_ANGABEN,
  TaxServiceName.ATTACHMENT_G,
  TaxServiceName.ATTACHMENT_S,
  TaxServiceName.ATTACHMENT_PENSION_EXPENSE,
  TaxServiceName.ATTACHMENT_SPECIAL_EXPENSE,
  TaxServiceName.ATTACHMENT_EXTRAORDINARY_EXPENSES,
  TaxServiceName.ATTACHMENT_HOUSEHOLD_RELATED_EXPENSES,
  TaxServiceName.ATTACHMENT_K,
  TaxServiceName.ATTACHMENT_AV,
  TaxServiceName.ATTACHMENT_JOINT_ASSESSMENT,
  TaxServiceName.TRANSACTIONAL_CONSULTING,
  TaxServiceName.INVOICING_ISSUES_INCLUDING_CORRECTION_OF_INVOICES,
  TaxServiceName.CAR_TAXATION_1_PERCENT_METHOD_LOGBOOK,
  TaxServiceName.TRAVEL_COST,
  TaxServiceName.HOME_OFFICE,
  TaxServiceName.BUSINESS_ASSETS,
  TaxServiceName.REPORT_MOVE_TO_THE_TAX_OFFICE,
];

export const FLAT_RATE_TAX_SERVICES = [TaxServiceName.OTHER_SERVICE_FLAT_RATE];

export const EXTERNAL_NOTE_TAX_SERVICES = [
  TaxServiceName.OTHER_SERVICE_FLAT_RATE,
];
